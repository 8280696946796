import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const AutoLogout = () => {
  const navigate = useNavigate();
  const timeoutRef = useRef(null);

  const resetTimer = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      handleLogout();
    }, 3 * 60 * 1000); // 3 minutes
  };

  const handleLogout = () => {
    sessionStorage.removeItem("token", { path: "/" });
    sessionStorage.removeItem("agentDetails", { path: "/" });
    sessionStorage.removeItem("userDetails", { path: "/" });
    sessionStorage.removeItem("agentData", { path: "/" });
    sessionStorage.removeItem("userdata", { path: "/" });
    localStorage.removeItem("agentData");
    localStorage.removeItem("userdata");
    navigate("/");
  };

  useEffect(() => {
    const events = ["click", "keydown", "scroll"];
    
    events.forEach(event => document.addEventListener(event, resetTimer));

    resetTimer(); // Start the timer

    return () => {
      events.forEach(event => document.removeEventListener(event, resetTimer));
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return null; // No UI needed
};

export default AutoLogout;
